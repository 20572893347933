export const validProduct = {
  title: true,
  type: true,
  price: true,
};

export const productValue = {
  title: "",
  type: "",
  price: 0,
};

export const productMessage = {
  title: "Nama Produk Wajib Diisi",
  type: "Kategori Produk Wajib Diisi",
  price: "Harga Product Wajib Diisi",
};

export default { validProduct, productValue, productMessage };
