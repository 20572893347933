
import ApiService from "@/core/services/ApiService";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
import Multiselect from "@vueform/multiselect";
import { ErrorMessage, Field, Form } from "vee-validate";
import { defineComponent } from "vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { useToast } from "vue-toastification";
import { productMessage, productValue, validProduct } from "./validateData";

export default defineComponent({
  name: "Add Product",
  components: {
    ErrorMessage,
    Field,
    Form,
    Loading,
    Multiselect,
  },

  data() {
    return {
      isLoading: false,
      fullPage: true,
      isColor: "#007BFF",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,

      listCategory: [],
      listType: [],
      noImg: "/media/no-image/No_Image_Horizontal_TKI.png",
      urlImage: {
        url1: "",
        url2: "",
        url3: "",
        url4: "",
        url5: "",
      },
      url: "https://st4.depositphotos.com/14953852/24787/v/600/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg",
      deleteImg: "../../../../assets/images/delete-product.svg",
      uploadImg: "../../../../assets/images/upload-product.svg",
      errorsMessage: {
        errortitle: "",
        errortype: "",
        errorprice: "",
      },
      valid: {
        ...validProduct,
      },

      detail: {
        title: "",
        createTime: "2022-02-15T13:33:33",
        creatorId: "",
        deleteBy: "",
        deleteTime: "",
        description: "",
        discount: 0,
        editorId: "",
        imageshow: "",
        imageProduct: {
          image1: "",
          image2: "",
          image3: "",
          image4: "",
          image5: "",
        },
        isDelete: false,
        price: 0,
        type: "",
        category: "",
        stock: 0,
        tax: 0,
        updateTime: "",
        _id: "",
      },

      targetDataImage: {
        file1: "",
        file2: "",
        file3: "",
        file4: "",
        file5: "",
      },
      categoryList: ["Produk", "Produk Jasa"],

      jumlahfoto: 1,
      imgCount: 5,
    };
  },

  beforeMount() {
    store.dispatch(Actions.VERIFY_AUTH);
    this.getListKategori();
    this.getListType();
  },

  methods: {
    changeImage(item) {
      console.log(item);
      this.detail.imageshow = item;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    getListKategori() {
      this.isLoading = true;
      ApiService.getWithoutSlug("crmv2/order/product/util/combo/category").then(
        ({ data }) => {
          this.listCategory = data;
          this.detail.category = data[0];
          localStorage.setItem("listCategory", JSON.stringify(data));
        }
      );
      this.isLoading = false;
    },
    getListType() {
      this.isLoading = true;
      ApiService.getWithoutSlug("crmv2/order/product/util/combo/type").then(
        ({ data }) => {
          this.listType = data;
          localStorage.setItem("listType", JSON.stringify(data));
        }
      );
      this.isLoading = false;
    },

    validateForm() {
      console.log("asdasdasd");
      this.valid = { ...validProduct };
      let check = { ...productValue };
      this.errorsMessage.errortitle = "";
      this.errorsMessage.errortype = "";
      this.errorsMessage.errorprice = "";

      Object.keys(check).map((item) => {
        switch (typeof check[item]) {
          case "string":
            if (!this.detail[item] || check[item] == this.detail[item]) {
              this.errorsMessage[`error${item}`] = productMessage[item];
              this.valid[item] = false;
            }
            break;
          case "number":
            if (!this.detail[item] || this.detail[item] < check[item]) {
              this.errorsMessage[`error${item}`] = productMessage[item];
              this.valid[item] = false;
            }
            break;
        }
      });
      return Object.values(this.valid).reduce((prev, next) => prev && next);
    },

    submitData() {
      // return console.log(this.validateForm());
      if (!this.validateForm()) {
        return;
      }
      const toast = useToast();

      this.isLoading = true;

      this.errorsMessage.errortitle = "";
      this.errorsMessage.errortype = "";
      this.errorsMessage.errorprice = "";

      const data = {
        title: this.detail.title,
        description: this.detail.description,
        price: this.detail.price,
        stock: this.detail.stock,
        discount: this.detail.discount,
        tax: this.detail.tax,
        imageProduct: this.detail.imageProduct,
        type: this.detail.type,
        category: this.detail.category,
      };

      let rslt = "";
      let idNewProduct = "";
      // return console.log(data);

      ApiService.postWithData("crmv2/order/product/admin", data)
        .then((res) => {
          this.isLoading = false;

          rslt = "success";
          idNewProduct = res.data._id;

          if (rslt == "success") {
            console.log("berhasil");
            console.log(idNewProduct);
            console.log(this.jumlahfoto);
          }

          let formData = new FormData();
          let upload = 0;
          for (let index = 0; index < this.imgCount; index++) {
            if (this.targetDataImage[`file${index + 1}`]) {
              formData.append(
                "files",
                this.targetDataImage[`file${index + 1}`]
              );
              upload++;
            }
          }
          // setTimeout(() => {
          if (upload > 0) {
            console.log("upload");
            ApiService.postWithData(
              "/crmv2/main_image/image/uploads/" + idNewProduct,
              formData
            )
              .then((res) => {
                // let imageProduct = [];
                console.log(res.data.process.items);
                res.data.process.items.map((img, i) => {
                  data.imageProduct[`image${i + 1}`] = img;
                  // imageProduct;
                });
                // console.log(data.imageProduct);
                // console.log(data);
                // setTimeout(() => {
                ApiService.putWithData(
                  "crmv2/product/admin/" + idNewProduct,
                  data
                )
                  .then((res) => {
                    toast.success("Berhasil Tambah File");

                    this.$router.push("/superadmin/master-produk");
                  })
                  .catch((e) => {});
                // }, 1000);
              })
              .catch((err) => {});
          } else {
            toast.success("Berhasil Tambah Produk");
            this.$router.push("/superadmin/master-produk");
          }
          // }, 1000);
        })
        .catch((e) => {
          this.isLoading = false;
          rslt = "error";
          toast.error(e.response.data.detail);
        });
    },

    // addfoto() {
    //   if (this.jumlahfoto < 5) {
    //     switch (this.jumlahfoto) {
    //       case 1:
    //         this.showfoto2 = true;
    //         break;
    //       case 2:
    //         this.showfoto3 = true;
    //         break;
    //       case 3:
    //         this.showfoto4 = true;
    //         break;
    //       case 4:
    //         this.showfoto5 = true;
    //     }
    //     let jumlahfotosekarang = this.jumlahfoto + 1;
    //     this.jumlahfoto = jumlahfotosekarang;
    //     console.log(jumlahfotosekarang);
    //   } else {
    //     const toast = useToast();
    //     toast.error("Foto Maksimal 5");
    //   }
    // },

    handleFileUpload(event, key) {
      this.urlImage[`url${key}`] = URL.createObjectURL(event.target.files[0]);
      this.targetDataImage[`file${key}`] = event.target.files[0];
    },
  },
});
